import React from 'react'; 
import '../../App.css';
import Footer from '../Footer';
import Contact from '../Contact';

export default function ContactUS() {
    return (
      <>

      <Contact />
      <Footer />
      </>
    );
}