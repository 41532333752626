import React from 'react'; 
import '../../App.css';
import ThingsWeDo from '../ThingsWeDo';
import Footer from '../Footer';

export default function Services() {
    return (
      <>
     <ThingsWeDo />
     <Footer />
      </>
    );
}