import React from 'react';
import './Cards.css';
import CardItem from './Carditem';

function Cards() {
  return (
    <div className='cards'>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/img-lab.png'
              text='Our company is founded on a culture of innovation'
              label='Innovation'
              path='/services'
            />
            <CardItem
              src='images/img-probes.jpg'
              text='Leverage cutting-edge hardware and software technologies'
              label='Hardware'
              path='/services'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/img-chess.jpg'
              text='Are you looking for new challenges in your career and opportunity to make a difference? Email us at recruitment@tectilium.com'
              label='Careers'
              path='/Contact'
            />
            <CardItem
              src='images/img-about.jpg'
              text='Tectilium is a leading bespoke software development and cyber security consulting company based in Canberra, Australia, serving clients in both the government and private sectors.'
              label='About Us'
              path='/About'
            />
            <CardItem
              src='images/img-software.jpg'
              text='Bespoke software development and cyber security'
              label='Software'
              path='/services'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
