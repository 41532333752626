import React from 'react';
import './Cards.css';
import CardItem from './Carditem';

function Cards() {
  return (
    <div className='cards'>
      <div className='cards__container'>
        <div className='cards__wrapper'>
        <ul className='cards__items'>
            <CardItem
              src='images/img-services.png'
              text=''
              label='Services'
            />
        </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/img-software.jpg'
              text='Our drive for innovation combined with our ability to think outside the box ensures every software development project we undertake delivers impact for the customer. We have proven experience in undertaking bespoke software development of mission critical systems where there is no margin for error. With skill-sets ranging from high level system and distributed networking solutions to low level kernel and assembly development, we thrive on the challenge of solving complex problems.'
              label='Bespoke Software Development'
            />
            <CardItem
              src='images/img-training.jpg'
              text="An organisation's most valuable asset is its people. We provide customised training courses and hands on workshops to assist organisations investing in their people. Whether your aim is to build a solid technical foundation for your graduates and new starters with OS fundamentals, or your senior employees are looking to upskill in a new area like low level programming, advanced forensics or reverse engineering, we can tailor training to suit your organisation's needs."
              label='Training & Workshops'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/img-integration.jpg'
              text='Often organisations invest heavily in acquiring different pieces of the puzzle in an effort to satisfy their requirements. Unfortunately, more often than not these are sourced from multiple vendors, and the responsibility falls on the organisation to integrate and transition these pieces to daily operations. We have extensive experience in assisting organisations with this critical component. Whether it is developing a low level kernel driver to ensure the new capability can interact with legacy systems, or producing in house training and associated risk mitigation strategies to accompany the deployment of this new capability, we are here to help with this final piece of the puzzle.'
              label='Systems Integration'
            />
            <CardItem
              src='images/img-hardware.jpg'
              text='Through a combination of manual source code review, reverse engineering, binary analysis and advanced fuzzing techniques we can assist your organisation in assessing the security of both open and closed sourced products. We have extensive experience undertaking such evaluations on Windows, Linux, mobile and IoT platforms combined with exploit development to highlight the severity of discovered vulnerabilities through proof of concepts (POCs).              '
              label='Vulnerability Research'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
