import React from 'react';
import './Cards.css';
import CardItem from './Carditem';

function Cards() {
  return (
    <div className='cards'>
      <div className='cards__container'>
        <div className='cards__wrapper'>
        <ul className='cards__items'>
            <CardItem
              src='images/img-aboutUs.jpg'
              text='We are a bespoke software development and cyber security consulting company. Based out of Canberra, Australia, we service both government and private sectors. We pride ourselves on creating real and measurable impact for each and every customer. Whatever your cyber security requirement we are ready to assist. Our company is built on a foundation of innovation. What some see as daunting, with the ever rapid evolution of technology, we see as an opportunity and we seek to take advantage of this whenever possible. Whether it is adapting a new hardware or software technology to satisfy a customer requirement or exploring a new access vector through our in house vulnerability research, we are constantly investing in up-skilling and training to ensure we always stay one step ahead of the curve.'
              label='About us'
            />
        </ul>
        <ul className='cards__items'>
            <CardItem
              src='images/img-coreValues.png'
              text=''
              label='Core Values'
            />
        </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/CoreValues/innovation.svg'
              text='Create, imagine, consider, embrace risk. Think outside the box and push the boundaries.'
              label='Innovation'
            />
            <CardItem
              src='images/CoreValues/growth.svg'
              text='Curious, question, challenge, share ideas, seek input from others. Daily growth mindset'
              label='Growth & Learning'
            />
            <CardItem
              src='images/CoreValues/humility.svg'
              text='Listen, learn, help. Open to ideas and sharing. Actions are our pride'
              label='Humility'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/CoreValues/transparency.svg'
              text=' Openly share, communicate and exchange context and information.'
              label='Transparency'
            />
            <CardItem
              src='images/CoreValues/integrity.svg'
              text='Truthful, respectful, sincere. We do what we say'
              label='Integrity'
            />
            <CardItem
              src='images/CoreValues/accountability.svg'
              text='Responsible for words, actions and results. Clear expectations for all'
              label='Accountability'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
