import React from 'react'; 
import '../../App.css';
import HeroSection from '../HeroSection';
import Cards from '../Cards';
import CoreValues from '../CoreValues';
import Footer from '../Footer';

function About() {
    return (
      <>
        <CoreValues />
        <Footer />
      </>
    );
}
export default About;


