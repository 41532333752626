import React from 'react';
import './Cards.css';
import CardItem from './Carditem';

function Cards() {
  return (
    <div className='cards'>
      <div className='cards__container'>
        <div className='cards__wrapper'>
        <ul className='cards__items'>
            <CardItem
              src='images/img-contact.jpg'
              text='For all enquires please email us on contact@tectilium.com'
              label='Services'
            /> 
        </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;