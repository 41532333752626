import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import companyLogo from '../logo.png';

function Footer() {
  return (
    <div className='footer-container'>
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>About Us</h2>
            <Link to='/About'>Who are we?</Link>
            <Link to='/About'>Core Values</Link>
          </div>
          <div className='footer-link-wrapper'>        
          <div class='footer-link-items'>
            <h2>Contact Us</h2>
            <Link to='/Contact'>Contact</Link>
          </div>
        </div>
          <div class='footer-link-items'>
            <h2>Social Media</h2>
            <Link to='https://www.linkedin.com/company/tectilium'>LinkedIn</Link>
          </div>
        </div>
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
              <img src={companyLogo} alt="asdf" width="10%"/> Tectilium
            </Link>
          </div>
          <small class='website-rights'>Tectilium Pty Ltd © 2023</small>
          <div class='social-icons'>
            <Link
              class='social-icon-link linked-in'
              to='https://www.linkedin.com/company/tectilium'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i class='fab fa-linkedin' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
